@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
body {
	margin: 0;
	font-family: 'DM Sans', sans-serif;
}

code {
	font-family: 'DM Sans', sans-serif;
}

.ant-modal-content {
	font-family: 'DM Sans', sans-serif;
	font-optical-sizing: auto;
}
.ant-drawer-content-wrapper {
	font-family: 'DM Sans', sans-serif;
	font-optical-sizing: auto;
}
